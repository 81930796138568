<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      :is-edit="true"
      :fields="fields"
      :row-data="row_data"
      post-api="/staffmanage/UpdateStaff"
      finish-router="staff"
      permission="Sta_Edit"
    >
      <template #facility="scope">
        <SlotFacilityRoleIDs :company-id="scope.rowData.companyID" :row-data="scope.rowData"/>
      </template>

      <template #floor="scope">
        <SlotFloor :row-data="scope.rowData" :facility-id="scope.rowData.facilityID"/>
      </template>

      <template v-slot:facilityNfcs="scope">
        <slot-inputs
          :is-edit="scope.isEdit"
          :root-item="scope.rootItem"
          :items="nfcTags"
          :show-add-btn="false"
          :show-delete-btn="false"
        />
      </template>

    </edit-field>
  </page-content>
</template>

<script>

import fields, { reloadFieldData } from '@/views/staff/fields'
import EditField from '@/components/EditField'
import EditFieldHeader from '@/components/EditFieldHeader'
import common from '@/common'
import PageContent from '@/components/PageContent'
import SlotFacilityRoleIDs from '@/views/staff/SlotFacilityRoleIDs'
import SlotFloor from '@/views/bed/SlotFloor'
import SlotInputs from '../../components/FieldInputs'

export default {
  name: 'Edit',
  components: {
    SlotFloor,
    SlotFacilityRoleIDs,
    PageContent,
    EditFieldHeader,
    EditField,
    fields,
    SlotInputs,
  },
  data() {
    return {
      title: common.getMenuName('staff') + ' - ' + common.getI18n('common.edit'),
      id: this.$router.currentRoute.params.id,
      fields,
      row_data: {},
    }
  },
  computed: {
    nfcTags() {
      if (this.row_data.facilityNfcs?.length >= 1) {
        return this.row_data.facilityNfcs.map(obj => {
          return obj.facilityName ? `${obj.nfcTag} (${obj.facilityName})` : obj.nfcTag
        })
      }
      return []
    },
  },
  created() {
    reloadFieldData()

    this.id = common.decrypt(this.id)
    if (this.id === undefined) {
      this.$router.push({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }

    common.apiGetData('/staffmanage/GetStaff?staffID=' + this.id)
      .then(res => {
        this.row_data = res.data.staff
      })

  }
}
</script>

<style scoped>

</style>
